.main-page {
  background-color: #1d2145;
  height: "100%";
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-page {
  height: "95%";
  width: 61%;
  background-color: #292c4e;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
}
.select-box {
  height: 39px;
  width: 12%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 16px;
  gap: 8px;
  background: #3c3e5c;
  margin: 10px;
  margin-left: 0%;
  color: #a0a2b1;
  border: none;
  outline: none;
  border-radius: 8px;
}
.navbar {
  width: 20%;
  height: 100%;
}
.hr-title {
  height: 0px;
  color: #a0a2b1;

  /* border: 1px solid rgba(160, 162, 177, 0.22); */
}
.image-configured {
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  flex-direction: column;
  /* align-items: flex-start; */
  padding: 15px;
  /* gap: 15%; */
  width: 100%;
  height: 100%;
  /* width: 230px; */
  /* height: 15%; */

  background: #3c3e5c;
  border-radius: 8px;
}
img {
  width: 100%;
  height: 100%;

  border-radius: 8px;
  display: block;
}
.image-details {
  /* display: flex;
flex-direction: column;
align-items: flex-start; */
  padding: 10px 0px;
  gap: 5px;

  width: 100%;
  height: 100%;
}
.comment-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 10px; */

  width: 100%;
  height: 100%;
}

.label-details {
  width: 36px;
  height: 10px;

  font-family: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: #6fa9fa;
}

.box-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 7px;

  width: 100%;
}
.button-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0px;
  gap: 7px;
  width: 100%;
  height: 100%;
}

.each-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 1px;
  height: 100%;

  background: #1d2145;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.each-button-inactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 1px;
  width: 50%;
  height: 100%;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.each-button-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  /* font-family: 'IBMPlexSans-Light'; */
}

.each-button-label-desc {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  /* font-family: 'IBMPlexSans-Medium'; */
}
.comment-input {
  /* display: block; */
  /* flex-direction: row;
align-items: center; */
  padding: 15px 16px 53px;
  gap: 8px;
  width: 100%;
  background: #1d2145;
  border: 1px solid #292c4e;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  outline: none;
  color: #ffffff;
  letter-spacing: 0.5px;
  height: 150px;
}
.hr-line {
  width: 100%;
  height: 0px;

  opacity: 0.5;
  border: 1px solid rgba(80, 82, 112, 0.9);
}
.submit-button {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: #6fa9fa;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #ffffff;
}
.comment-input-label {
  width: 100%;
  height: 10px;
  font-family: unset;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 9px;
  color: #ffffff;
}
