.login-page {
  background-color: #1d2145;
  height: 100vh;
  width: 100%;
  /* color: aliceblue; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-credentials {
  background-color: #292c4e;
  height: 40%;
  width: 25%;
  /* color: aliceblue; */
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 50px;
  gap: 30px;
  min-width: 270px;
}
.password-input {
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  padding: 10px 15px;
  letter-spacing: 0.5px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  color: #ffffff;
  outline: none;

  background: #3c3e5c;

  /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
  border-radius: 8px;
}

.input-label {
  width: 100%;
  height: 29px;

  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;

  color: #ffffff;
}

.login-button {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  font-size: 18px;
  /* padding: 20px 30px; */
  cursor: pointer;

  width: 40%;
  height: 56px;
  border: none;

  background: #6fa9fa;
  border-radius: 8px;
  color: #ffffff;
}

label {
  width: 100%;
  height: 29px;

  /* Text/H7 16PX Medium */

  font-family: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height, or 181% */

  /* Gray/White */

  color: #ffffff;
}

.login-parts {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 6px;

  width: 100%;
  height: 94px;
}
@media (max-width: 600px) {
  .login-credentials {
    box-sizing: border-box;
    height: 60%;
    width: 80%;
  }
}
@media (max-width: 380px) {
  .login-credentials {
    box-sizing: border-box;
    height: 65%;
    width: 80%;
  }
}
